import { Button, Form, Input, message } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../firebase/firebase.models';
import { gtm } from '../../../../reportGTM';
import { useAuth } from '../../AuthContext';

export const Step3Form: React.FC = () => {
  const [status, setStatus] = useState(2);
  const [resending, setResending] = useState(false);
  const { t } = useTranslation();
  const { sendVerificationEmail, closeModal } = useAuth();
  const { currentUser } = useAuth();

  // Handle resend verification code
  const handleResend = async () => {
    setStatus(1);
    setResending(true);
    const response = await sendVerificationEmail(currentUser.email);
    if (response.status === 'SUCCESS') {
      message.success('Kod weryfikacyjny został wysłany.');
    } else {
      toastService.show(response.message, 'Error!', { variant: ToastVariant.ERROR });
    }
    setResending(false);
    setStatus(2);
  };

  const onComplete = async (verificationCode: string) => {
    setStatus(1);
    const verifyEmailVerificationCode = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.VERIFY_EMAIL_VERIFICATION_CODE
    );
    try {
      const response = await verifyEmailVerificationCode({
        email: currentUser.email,
        verificationCode: verificationCode,
      });
      const { status, message } = response.data;

      if (status === 'SUCCESS') {
        toastService.show(message, 'Success!', { variant: ToastVariant.SUCCESS });
        gtm('form_submit_reg_step_3', { state: 'emailVerified' });
        closeModal();
        window.location.reload();
      } else {
        toastService.show(message, 'Error!', { variant: ToastVariant.ERROR });
      }
    } catch (error: any) {
      toastService.show('An error occurred while verifying the code.', 'Error!', { variant: ToastVariant.ERROR });
    } finally {
      setStatus(2);
    }
  };

  return (
    <Formik
      initialValues={{ verificationCode: '' }}
      validate={(values) => {
        const errors: { verificationCode?: string } = {};
        if (!/^\d{6}$/.test(values.verificationCode)) {
          errors.verificationCode = 'The verification code must consist of 6 digits.';
        }
        return errors;
      }}
      onSubmit={(values: { verificationCode: string }, formikHelpers: FormikHelpers<{ verificationCode: string }>) => {
        onComplete(values.verificationCode);
        formikHelpers.setSubmitting(false);
      }}>
      {({ isSubmitting, isValid, submitForm, handleChange, handleBlur, values }) => (
        <Form layout="vertical" onFinish={submitForm}>
          <div className="flex items-center justify-center">
            <Input.OTP
              length={6}
              onChange={(value) => handleChange({ target: { name: 'verificationCode', value } })}
              value={values.verificationCode}
              onBlur={handleBlur}
              placeholder="Enter 6-digit code"
            />
          </div>

          <Form.Item>
            <Button
              className="mt-4"
              block
              type="primary"
              loading={isSubmitting || status === 1}
              disabled={!isValid}
              onClick={submitForm}>
              {t('FEATURES.AUTH.SIGN_UP.BUTTON_VERIFY')}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="link"
              onClick={handleResend}
              disabled={resending || status === 1}
              style={{ marginTop: '10px' }}>
              {resending ? t('FEATURES.AUTH.SIGN_UP.BUTTON_RESENDING') : t('FEATURES.AUTH.SIGN_UP.BUTTON_RESEND')}
            </Button>
          </Form.Item>

          {status === 3 && (
            <div
              style={{
                color: 'red',
                marginTop: '10px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {t('FEATURES.AUTH.SIGN_UP.ERROR_SENDING_EMAIL')}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
