// AlertMessage.tsx
import { Alert, Button, Spin } from 'antd';
import React from 'react';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { Verification } from '../../../../../models/offers.model';
import { AppRoutes } from '../../../../Routing/routing.model';

interface AlertMessageProps {
  isLoading: boolean;
  showEmailAlert: boolean;
  identity: Verification;
  push: (path: string) => void;
  onAddOfferPush: () => void;
  sendVerificationEmail: () => Promise<any>;
  t: (key: string) => string;
  toastService: any;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  isLoading,
  showEmailAlert,
  identity,
  push,
  onAddOfferPush,
  sendVerificationEmail,
  t,
  toastService,
}) => {
  if (isLoading) {
    return <Spin style={{ margin: '50px' }} />;
  } else if (showEmailAlert) {
    return (
      <Alert
        style={{ width: '400px' }}
        message="Potwierdź adres email"
        description="Obecnie nie masz dostępu do tej funkcjonalności."
        type="error"
        showIcon
        action={
          <Button
            type="primary"
            danger
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              sendVerificationEmail()
                .then((response) => {
                  if (response.status === 'SUCCESS') {
                    toastService.show(t('FEATURES.AUTH.SIGN_UP.VERIFICATION_EMAIL_SENT'), 'Potwierdź email', {
                      variant: ToastVariant.SUCCESS,
                    });
                  } else {
                    toastService.show(t(response.message), 'Błąd!', { variant: ToastVariant.ERROR });
                  }
                })
                .catch((e) => {
                  toastService.show(t(e.message || 'Wystąpił nieoczekiwany błąd.'), 'Błąd!', {
                    variant: ToastVariant.ERROR,
                  });
                });
            }}>
            Potwierdź
          </Button>
        }
      />
    );
  } else {
    switch (identity) {
      case Verification.INITIAL:
        return (
          <>
            <Alert
              message="Potwierdź tożsamość"
              description="Potwierdź tożsamość przez wysłanie podpisanego regulaminu przez Podpis Zaufany"
              type="info"
              showIcon
              className="mb-4"
            />
            <Alert
              message="Dlaczego potrzebujemy Twojego numeru konta?"
              description="Twój numer konta bankowego jest dla nas istotny, abyśmy mogli w przyszłości wypełnić wszelkie umowy i dokumenty związane z naszymi usługami. Twoje dane są przechowywane w bezpieczny sposób i są wykorzystywane jedynie w celach związanych z zawarciem i realizacją umów. Dzięki temu proces zawierania transakcji będzie dla Ciebie znacznie szybszy i wygodniejszy."
              type="info"
              showIcon
              className="mb-4"
            />
          </>
        );
      case Verification.ACCEPTED:
        return (
          <Alert
            message="Twoja tożsamość jest potwierdzona"
            description="Cieszymy się, że udało Ci się przejść przez proces potwierdzania tożsamości."
            type="success"
            showIcon
          />
        );
      case Verification.CHECKING:
        return (
          <Alert
            message="Trwa weryfikacja podpisu"
            description={
              <>
                <p>Poinformujemy Cię gdy weryfikacja podpisu się zakończy. Szacowany czas: 24h</p>
                <p>
                  Oczekując na sprawdzenie podanych przez Ciebie danych, zachęcamy do{' '}
                  <a
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      push(AppRoutes.OFFERS);
                    }}>
                    sprawdzenia ofert
                  </a>{' '}
                  oraz do{' '}
                  <a
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      onAddOfferPush();
                    }}>
                    dodania własnej
                  </a>
                  .
                </p>
              </>
            }
            type="warning"
            showIcon
          />
        );
      case Verification.REJECTED:
        return (
          <Alert
            message="Nie udało się zweryfikować podpisu. Spróbuj jeszcze raz"
            description="Podpis Profilem Zaufanym był nieprawidłowy lub dane na nim nie zgadzały się z danymi konta."
            type="error"
            showIcon
          />
        );
      default:
        return null;
    }
  }
};
