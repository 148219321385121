// ConfirmIdentity.tsx
import { Form, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { firebaseStorage, firestore } from '../../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../../firebase/firebase.models';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { App } from '../../../../App/App';
import { useOnAddOfferPush } from '../../../../App/hooks/onAddOfferPush.hook';
import { useAuth } from '../../../../Auth/AuthContext';
import { useDocumentModal } from '../../../../Documents/DocumentModalProvider';
import { AppRoutes } from '../../../../Routing/routing.model';
import { MenuWrapper } from '../../../components/MenuWrapper';
import { additionalNavigation } from '../../../Menu/AdditionalNavigation';
import { MenuItems } from '../../../Menu/Menu';

import { AlertMessage } from './AlertMessage';
import { BankAccountForm } from './BankAccountForm';
import { FileUploadComponent } from './FileUploadComponent';
import {
  fetchUserData,
  getDownloadUrl,
  notifyAdmin,
  updateUserBankAccount,
  updateUserVerificationStatus,
  uploadFile,
} from './firebaseIdentityService';

const FILE_NAME = 'Regulamin';

export const ConfirmIdentity: React.FC = () => {
  const { push } = useHistory();
  const { currentUser, sendVerificationEmail } = useAuth();
  const [form] = Form.useForm();
  const [identity, setIdentity] = useState<Verification>();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [bankAccount, setBankAccount] = useState<string>('');
  const [showEmailAlert, setShowEmailAlert] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState(false);
  const [signedTerms, setSignedTerms] = useState('');
  const onAddOfferPush = useOnAddOfferPush();
  const { showDocumentModal } = useDocumentModal();

  const { t } = useTranslation();

  const onClick = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  useEffect(() => {
    if (!isUploading) {
      fetchData();
    }

    if (!currentUser.emailVerified) {
      setShowEmailAlert(true);
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      await Promise.all([loadFile(), loadUserData()]);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const loadFile = async () => {
    try {
      const downloadUrl = await getDownloadUrl(currentUser.uid, FILE_NAME);
      setFileList([{ uid: '0', name: FILE_NAME, status: 'done', url: downloadUrl }]);
      setSignedTerms(downloadUrl);
    } catch (error) {
      console.error(error);
      setFileList([]);
    }
  };

  const loadUserData = async () => {
    const data = await fetchUserData(currentUser.uid);

    if (data) {
      setIdentity(data.verification?.identity || Verification.INITIAL);
      const bankAccountFromDb = data.bankAccount || '';
      setBankAccount(bankAccountFromDb);
      form.setFieldsValue({ bankAccount: bankAccountFromDb });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          if (data && data.verification) {
            setIdentity(data.verification.identity || Verification.INITIAL);
          }
        },
        (e) => {
          console.log('Error', e);
        }
      );

    return () => unsubscribe();
  }, [currentUser.uid]);

  const handleInvalidBankAccount = (options) => {
    const { onError } = options;
    const errorMessage = 'Proszę wpisać numer konta bankowego przed wysłaniem pliku.';

    onError(new Error(errorMessage));
    setIdentity(Verification.INITIAL);

    toastService.show(errorMessage, 'Błąd', {
      variant: ToastVariant.ERROR,
    });
  };

  const customUpload = async (options) => {
    if (!bankAccount) {
      handleInvalidBankAccount(options);
      return;
    }

    const { file, onError, onSuccess } = options;
    setIsUploading(true);

    try {
      const downloadUrl = await uploadFile(file, `/users/${currentUser.uid}/Regulamin`);
      setSignedTerms(downloadUrl);
      onSuccess({ url: downloadUrl }, file);

      const snapshot = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
      const userData = snapshot.data();

      if (!userData) {
        throw new Error('Brak danych użytkownika');
      }

      await notifyAdmin({
        userUid: currentUser.uid,
        fileUrl: downloadUrl,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });

      toastService.show('Poinformujemy Cię, gdy zakończy się weryfikacja', 'Udało się');
      setUploaded(true);
      gtm('form_submit_reg_step_5', { state: 'RegulationsSignedSubmitted' });
    } catch (error) {
      onError(error);
      toastService.show('Coś poszło nie tak. Spróbuj jeszcze raz', 'Błąd', { variant: ToastVariant.ERROR });
    } finally {
      setIsUploading(false);
    }
    try {
      await updateUserVerificationStatus(currentUser.uid, Verification.CHECKING);
      setIdentity(Verification.CHECKING);
    } catch (error) {
      console.error('Error updating user verification status: ', error);
    }
  };

  const handleSendClick = async () => {
    try {
      const cleanBankAccount = form.getFieldValue('bankAccount');
      setBankAccount(cleanBankAccount);
      await updateUserBankAccount(currentUser.uid, cleanBankAccount);
      gtm('form_submit_reg_step_4', { state: 'bankAccountSubmitted' });
      toastService.show('Udało się zapisać numer konta bankowego', 'Sukces');
    } catch (error) {
      toastService.show('Nie udało się zapisać danych', 'Błąd!', { variant: ToastVariant.ERROR, duration: 0 });
    }
  };

  const handleDownloadRegulamin = async () => {
    const fileRef = firebaseStorage.ref('documents/FL_Regulamin.pdf');

    try {
      const url = await fileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();

      // Tworzenie linku do pobrania pliku
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Regulamin najmuje.com.pdf'; // Nazwa pliku po pobraniu
      link.click();
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  };

  return (
    <App>
      <div className="flex">
        <MenuWrapper>
          <MenuItems
            onClick={onClick}
            defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY]}
            defaultOpenKeys={['sub2', 'sub3']}
          />
        </MenuWrapper>
        <Space direction="vertical" style={{ margin: 50 }}>
          <AlertMessage
            isLoading={isLoading}
            showEmailAlert={showEmailAlert}
            identity={identity}
            push={push}
            onAddOfferPush={onAddOfferPush}
            sendVerificationEmail={sendVerificationEmail}
            t={t}
            toastService={toastService}
          />
          {!isLoading && !showEmailAlert && (
            <>
              <BankAccountForm form={form} onFinish={handleSendClick} />
              <FileUploadComponent
                bankAccount={bankAccount}
                uploaded={uploaded}
                identity={identity}
                isUploading={isUploading}
                fileList={fileList}
                customUpload={customUpload}
                handleDownloadRegulamin={handleDownloadRegulamin}
              />
              {signedTerms ? (
                <a onClick={() => showDocumentModal(signedTerms)} rel="noreferrer" className="text-blue-500">
                  Zobacz podpisany regulamin
                </a>
              ) : null}
            </>
          )}
        </Space>
      </div>
    </App>
  );
};
