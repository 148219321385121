import { Image, Skeleton } from 'antd';
import { fabric } from 'fabric';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { firebaseStorage } from '../../../firebase/firebase';

const StyledButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px;
  border-radius: 20%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: black;
`;
const StyledInput = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
`;

const handleUpload = async (file: File, id: string, order: number): Promise<string> => {
  try {
    const splittedFileName = file.name.split('.');
    const fileExtension = splittedFileName[splittedFileName.length - 1];
    const fileName = `${order}_${uuidv4()}.${fileExtension}`;
    const imageRef = firebaseStorage.ref(`/offers/${id}/images/` + fileName);

    // Wczytaj plik jako buffer
    const buffer = await file.arrayBuffer();

    // Konwersja buffer na Blob
    const blob = new Blob([buffer]);

    // Odczyt obrazów za pomocą fabric.js
    const image = await new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(
        URL.createObjectURL(blob),
        (img) => {
          resolve(img);
        },
        { crossOrigin: 'Anonymous' }
      );
    });

    const watermark = await new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(
        `/images/logo/Logo-black.png`,
        (img) => {
          resolve(img);
        },
        { crossOrigin: 'Anonymous' }
      );
    });

    // Utwórz canvas i dodaj obraz oraz watermark
    const canvas = new fabric.Canvas(null, { width: image.width, height: image.height });
    canvas.add(image);

    // Stała pozycja watermarka - prawy dolny róg z marginesem
    const marginX = 20; // stały margines 20 px od prawej krawędzi
    const marginY = 20; // stały margines 20 px od dolnej krawędzi

    watermark.scaleToWidth(image.width * 0.2); // Przeskaluj watermark do 20% szerokości obrazu
    watermark.scaleToHeight(image.height * 0.2); // Przeskaluj watermark do 20% wysokości obrazu

    watermark.set({
      left: image.width - watermark.getScaledWidth() - marginX,
      top: image.height - watermark.getScaledHeight() - marginY,
      opacity: 0.5,
    });
    canvas.add(watermark);

    // Konwersja canvas na Blob
    const dataUrl = canvas.toDataURL({ format: 'jpeg', quality: 0.8 });
    const watermarkedBlob = await (await fetch(dataUrl)).blob();

    // Prześlij obraz z watermarkiem do Firebase Storage
    const snapshot = await imageRef.put(watermarkedBlob, {
      contentType: file.type,
    });

    const downloadUrl = await snapshot.ref.getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const handleDelete = async (url: string): Promise<void> => {
  try {
    const fileRef = firebaseStorage.refFromURL(url);
    await fileRef.delete();

    // Usuń kopię z folderu "small/"
    const filePath = fileRef.fullPath;
    const smallFilePath = filePath.replace(/(.*\/)([^/]+)$/, '$1small/small_$2');
    const smallFileRef = firebaseStorage.ref(smallFilePath);

    await smallFileRef.delete();
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

interface Props {
  setUrls: Dispatch<SetStateAction<string[]>>;
  urls: string[];
  id: string;
  onUploadComplete?: () => void;
}

interface ItemInterface {
  id: string;
  url: string;
  label: string;
}

export const UploadPhotos: React.FC<Props> = ({ setUrls, urls, id, onUploadComplete }) => {
  const [items, setItems] = React.useState<ItemInterface[]>([]);
  const [reordering, setReordering] = useState(false);

  React.useEffect(() => {
    if (urls) {
      console.log(urls);
      setItems(urls.map((url, index) => ({ url: url, label: `Image ${index + 1}`, id: `${index + 1}` })));
    }
  }, [urls]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files;
      if (!files) return;

      const uploadedUrls = await Promise.all(Array.from(files).map((file) => handleUpload(file, id, files.length + 1)));

      setUrls((prevUrls) => [...prevUrls, ...uploadedUrls]);

      setItems((prevItems) => [
        ...prevItems,
        ...uploadedUrls.map((url, index) => ({
          url: url,
          label: `Image ${prevItems.length + index + 1}`,
          id: `${index + 1}`,
        })),
      ]);

      if (onUploadComplete) {
        onUploadComplete();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveImage = (url: string) => {
    console.log(url);
    handleDelete(url);
    setUrls((prevUrls) => prevUrls.filter((u) => u !== url));
    setItems((prevItems) => prevItems.filter((item) => item.id !== url));
  };

  const handleReorder = async (newState: ItemInterface[]) => {
    // Check if the new order is different from the current order

    if (!newState[0] || !items[0]) return;
    if (newState[0].url !== items[0].url) {
      console.log('Reordering detected, updating files accordingly...');
      setReordering(true);

      // Iterate over the reordered list and process each item
      for (let i = 0; i < newState.length; i++) {
        const item = newState[i];
        const originalUrl = item.url;

        try {
          // Step 1: Download the image blob from the current URL
          const blob = await fetch(originalUrl).then((res) => res.blob());

          // Step 2: Delete the current image from Firebase Storage
          await handleDelete(originalUrl);

          // Step 3: Upload the image again with the new order number in the filename
          const newFileName = `${i + 1}_${uuidv4()}`;
          const newUrl = await handleUpload(new File([blob], newFileName), id, i + 1);

          // Step 4: Update the item's URL and ID with the new information
          item.url = newUrl;
          item.id = `${i + 1}`;
          item.label = `Image ${i + 1}`;

          console.log(`Re-uploaded image to: ${newUrl}`);
        } catch (error) {
          console.error('Error during re-upload process:', error);
        }
      }
    }

    // Update state to reflect the new order visually
    setItems(newState);
    setUrls(newState.map((item) => item.url)); // Sync `urls` with the new order
    console.log('Reordering completed:', newState);
    setReordering(false);
  };

  const { t } = useTranslation();

  return (
    <PanelWhite header={t('COMMON.FORMS.NAMES.ATTACH_PHOTOS')} defaultExpanded collapsible>
      <PanelContent>
        <StyledInput type="file" accept="image/png, image/jpg, image/jpeg" onChange={handleFileUpload} multiple />

        <br />
        <br />

        {/* {urls
          ? urls.map((url) => (
              <div key={url} style={{ display: 'inline-block', position: 'relative', margin: '10px' }}>
                <Image width={150} src={url} />
                <StyledButton className="bg-gray-200" onClick={() => handleRemoveImage(url)}>
                  X
                </StyledButton>
              </div>
            ))
          : null} */}

        <br />

        <ReactSortable
          // filter=".addImageButtonContainer"
          // dragClass="sortableDrag"

          list={items}
          setList={handleReorder}
          animation={200}
          easing="ease-out">
          {items.map((item) =>
            reordering ? (
              <Skeleton.Image active={true} key={item.id} className="m-3" />
            ) : (
              <div
                key={item.id}
                style={{ display: 'inline-block', position: 'relative', margin: '10px' }}
                className="draggableItem">
                <Image width={150} src={item.url} alt={item.label} /> {/* Display the image */}
                <StyledButton className="bg-gray-200" onClick={() => handleRemoveImage(item.url)}>
                  X
                </StyledButton>
              </div>
            )
          )}
        </ReactSortable>
      </PanelContent>
    </PanelWhite>
  );
};
