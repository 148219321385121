import { Button, Checkbox, Form, Input } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { firestore } from './../../../firebase/firebase';
import { FirestoreCollection } from './../../../firebase/firebase.models';
import { ModalConfig, useAuth } from './../AuthContext';
import { NonEmailSignUpFormValues } from './../auth.model';
import { useOnSubmit } from './hooks/useOnSubmit.hool';
import { useValidation } from './hooks/useValidation.hook';

export const NonEmailSignUp: React.FC<Pick<ModalConfig, 'onSuccess'>> = ({ onSuccess }) => {
  const validation = useValidation();
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<NonEmailSignUpFormValues>({
    firstName: '',
    lastName: '',
    marketingConsent: false,
    email: '',
    uid: '',
  });

  // Update initialValues when currentUser is loaded
  useEffect(() => {
    const saveToFirestore = async (userData: NonEmailSignUpFormValues) => {
      try {
        const userRef = firestore.collection(FirestoreCollection.USERS).doc(userData.uid);
        await userRef.set(userData, { merge: true });
        console.log('User data saved successfully');
      } catch (error) {
        console.error('Error saving user data:', error);
      }
    };

    if (currentUser) {
      const newValues = {
        email: currentUser.email || '',
        uid: currentUser.uid || '',
        firstName: currentUser.displayName?.split(' ')[0] || '',
        lastName: currentUser.displayName?.split(' ').slice(1).join(' ') || '',
      };

      setInitialValues((prevValues) => ({
        ...prevValues,
        ...newValues,
      }));

      saveToFirestore(initialValues);
    }
  }, [currentUser]);

  const onSubmit = useOnSubmit(onSuccess);

  // Conditional rendering of Formik
  if (!currentUser || !initialValues.uid || !initialValues.email) {
    return <div>Ładowanie danych użytkownika...</div>;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')}
            validateStatus={touched.firstName && errors.firstName ? 'error' : ''}
            help={touched.firstName && errors.firstName ? errors.firstName : ''}>
            <Input
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item
            label={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')}
            validateStatus={touched.lastName && errors.lastName ? 'error' : ''}
            help={touched.lastName && errors.lastName ? errors.lastName : ''}>
            <Input
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item>
            <Checkbox name="marketingConsent" checked={values.marketingConsent} onChange={handleChange}>
              {t('FEATURES.AUTH.SIGN_UP.INPUT_MARKETING_CONSENT_PLACEHOLDER')}
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" disabled={isSubmitting || !isValid} loading={isSubmitting}>
              {t('FEATURES.AUTH.SIGN_UP.SAVE')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
