import { Alert, Button, Checkbox, DatePicker, Form, Input, Popover, Space, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { useUserData } from '../../../firebase/hooks/useUserData.hook';
import { Offer, ReceptionForm } from '../../../models/offers.model';
import { useAuth } from '../../Auth/AuthContext';
import { RowBox } from '../../OfferAdd/components/OfferInputs';
import { AppRoutes } from '../../Routing/routing.model';
import { useInitialReceptionFormValues } from '../hooks/useInitialReceptionFormValues.hook';
import { useUpdateReceptionOfThePremise } from '../hooks/useUpdateReceptionOfThePremise.hook';

interface Props {
  id: string;
  landlord?: boolean;
  offer: Offer;
}

export const ReceptionOfThePremises: React.FC<Props> = ({ id, landlord, offer }) => {
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [form] = Form.useForm();
  const [dateAlert, setDateAlert] = useState(false);
  const { push } = useHistory();
  const { initialValues, isLoading, receptionTime } = useInitialReceptionFormValues(id, landlord);
  const [isSending, setIsSending] = useState(false);
  const { currentUser } = useAuth();
  const [isPermissionToggleVisible, setIsPermissionToggleVisible] = useState(false);

  const [userData] = useUserData(currentUser.uid);

  form.setFieldsValue(initialValues);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setDisabled(true);
      if (!landlord) {
        form.setFieldsValue({
          date: dayjs(receptionTime?.date),
          hour: dayjs(receptionTime?.hour),
        });
      }
    }
  }, [initialValues, isLoading]);

  const onFormChange = (_, allValues) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + offer.unitReceiptDeadline);

    if (allValues.date > maxDate) {
      setDateAlert(true);
    } else setDateAlert(false);
  };

  const onFormSubmit = async (values: any) => {
    setIsSending(true);
    const data: ReceptionForm = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      date: values.date,
      hour: values.hour,
      authorized: values.authorized,
    };

    const [status, error] = await useUpdateReceptionOfThePremise(data, id, landlord);

    if (status) {
      setDisabled(true);
      setShowAlert(false);
      toastService.show('Dane zostały zapisane', 'Sukces!', { variant: ToastVariant.SUCCESS });
      if (!landlord) {
        // żeby pokazać, że dane są zapisane
        // żeby przejście było bardziej naturalne
        setTimeout(() => {
          push(AppRoutes.OFFER_DEPOSIT.replace(':id', id));
        }, 1000);
      }
    } else {
      if (error == 'user not verified') {
        setShowAlert(true);
        toastService.show('Użytkownik, który ma odebrać Lokal nie spełnia kryteriów', 'Błąd!', {
          variant: ToastVariant.ERROR,
        });
      } else {
        toastService.show('Wystąpił błąd podczas zapisu danych', 'Błąd!', { variant: ToastVariant.ERROR });
      }
      setIsSending(false);
      return;
    }
    setIsSending(false);
  };

  const onChange = (checked: boolean) => {
    setIsPermissionToggleVisible(checked);
    if (checked) {
      const userFormData: ReceptionForm = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        authorized: true,
        date: initialValues?.date,
        hour: initialValues?.hour,
      };
      form.setFieldsValue(userFormData);
    } else {
      const userFormData: ReceptionForm = {
        firstName: initialValues?.firstName,
        lastName: initialValues?.lastName,
        email: initialValues?.email,
        authorized: false,
        date: initialValues?.date,
        hour: initialValues?.hour,
      };
      form.setFieldsValue(userFormData);
    }
  };

  return (
    <PanelWhite
      className="float-left text-left border-2 m-6"
      bodyFill
      header={
        <>
          Wskazanie osoby upoważnionej do odbioru Lokalu
          <Space direction="vertical" style={{ marginLeft: 20 }}>
            <Switch
              defaultChecked={currentUser.email == initialValues?.email}
              onChange={onChange}
              checkedChildren="Osobiście"
              unCheckedChildren="Nieosobiście"
              disabled={disabled}
            />
          </Space>
        </>
      }>
      <PanelContent>
        {showAlert && (
          <Space direction="vertical" style={{ marginBottom: 20, marginTop: -20 }}>
            <Alert
              message="Użytkownik nie spełnia kryteriów"
              description="Użytkownik nie widnieje w systemie lub nie ma potwierdzonej tożsamości."
              type="error"
              showIcon
            />
          </Space>
        )}
        {disabled && (
          <Space direction="vertical" style={{ marginBottom: 20, marginTop: -20 }}>
            <Alert
              message={
                <div>
                  Gdy kaucja zostanie wpłacona przez najemce na nasze konto bankowe{' '}
                  <a className="text-blue-500" href={AppRoutes.PROTOCOL.replace(':id', offer.id)}>
                    protokół zdawczo odbiorczy
                  </a>{' '}
                  zostanie odblokowany.
                </div>
              }
              type="info"
              showIcon
            />
          </Space>
        )}
        <Form form={form} onValuesChange={onFormChange} onFinish={onFormSubmit} disabled={disabled}>
          <RowBox>
            <Form.Item name="firstName" label="Imię" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Nazwisko"
              rules={[{ required: true, message: 'Pole nie może być puste' }]}>
              <Input />
            </Form.Item>
          </RowBox>
          <RowBox>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
              <Input style={{ width: '250px' }} />
            </Form.Item>
          </RowBox>

          {!landlord && (
            <>
              <Form.Item
                name="date"
                label="Data odbioru Lokalu"
                rules={[
                  { required: true, message: 'Pole nie może być puste' },
                  {
                    message: `Wynajmujący określił ${offer.unitReceiptDeadline} dni na odbiór Lokalu`,
                    validator: () => {
                      if (!dateAlert) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.isAfter(new Date())) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Wybrana data powinna być w przyszłości');
                    },
                  },
                ]}>
                <DatePicker />
              </Form.Item>
              <RowBox>
                <Popover
                  placement="right"
                  content={
                    <div style={{ maxWidth: '250px' }}>
                      Pamiętaj, że będziesz miał godzinę na wysłanie podpisanego protokołu od rozpoczęcia spotkania.
                    </div>
                  }>
                  <Form.Item
                    name="hour"
                    label="Godzina odbioru Lokalu"
                    rules={[{ required: true, message: 'Pole nie może być puste' }]}>
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Popover>
              </RowBox>
            </>
          )}

          <RowBox>
            <Form.Item
              hidden={isPermissionToggleVisible}
              name="authorized"
              valuePropName="checked"
              label="Upoważniam powyższą osobę do odebrania Lokalu w moim imieniu"
              className="reverse-label"
              rules={[
                {
                  message: 'Wymagane',
                  validator: (_, value) => {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}>
              <Checkbox />
            </Form.Item>
          </RowBox>

          <Button type="primary" htmlType="submit" disabled={disabled} loading={isSending}>
            Zatwierdzam
          </Button>
        </Form>
      </PanelContent>
    </PanelWhite>
  );
};
