// BankAccountForm.tsx
import { Button, Form, Popover } from 'antd';
import MaskedInput from 'antd-mask-input';
import React, { useEffect, useState } from 'react';

interface BankAccountFormProps {
  form: any;
  onFinish: () => Promise<void>;
}

export const BankAccountForm: React.FC<BankAccountFormProps> = ({ form, onFinish }) => {
  const [isEditable, setIsEditable] = useState(false); // State to manage form editability

  // Function to check if the form is empty and set the form to be editable
  const checkIfFormIsEmpty = () => {
    const values = form.getFieldsValue();
    if (!values.bankAccount) {
      setIsEditable(true);
    }
  };

  // Handle the form submission
  const handleFinish = async () => {
    await onFinish();
    setIsEditable(false); // Set form back to inactive after submission
  };

  // Handle the "Edit" button click
  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default action that may cause form submission
    setIsEditable(true);
  };

  // Use useEffect to run the check on component mount
  useEffect(() => {
    checkIfFormIsEmpty();
  }, []);

  return (
    <Form form={form} onFinish={handleFinish}>
      <Popover
        trigger="focus"
        placement="top"
        content={
          <div className="font-semibold w-96">
            Pamiętaj, że zmieniając już wcześniej wpisany numer konta, dotychczasowy numer konta nie zmieni się w Twoich
            aktywnych ofertach.
          </div>
        }>
        <Form.Item
          label="Numer konta bankowego"
          name="bankAccount"
          rules={[
            {
              validator: (_, value) => {
                const cleanedValue = value.replace(/\s/g, '');
                return cleanedValue.length === 26
                  ? Promise.resolve()
                  : Promise.reject(new Error('Pole musi mieć dokładnie 26 znaków'));
              },
            },
          ]}>
          <MaskedInput
            mask="00 0000 0000 0000 0000 0000 0000" // Mask with spaces
            placeholder="00 0000 0000 0000 0000 0000 0000"
            disabled={!isEditable} // Disable input if not in edit mode
          />
        </Form.Item>
      </Popover>
      <Form.Item>
        {isEditable ? (
          <Button type="primary" htmlType="submit">
            Zapisz
          </Button>
        ) : (
          <Button type="default" onClick={handleEditClick}>
            Edytuj
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
