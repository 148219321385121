import { Divider, Modal } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthDialog } from './auth.model';
import { ModalProps, useAuth } from './AuthContext';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { NonEmailSignUp } from './NonEmailSignUp/NonEmailSignUp';
import { SignIn } from './SignIn/SignIn';
import { Step3Form } from './SignUp/components/Step3Form';
import { SignUp } from './SignUp/SignUp';

interface Props {
  dialogConfig: ModalProps;
  showDialog: boolean;
}
export const AuthModal: React.FC<Props> = ({ dialogConfig, showDialog }) => {
  const { closeModal } = useAuth();
  const { t } = useTranslation();

  const getTitle = () => {
    switch (dialogConfig?.content) {
      case AuthDialog.SIGN_IN:
        return t('FEATURES.AUTH.SIGN_IN.HEADER');
      case AuthDialog.SIGN_UP:
        return t('FEATURES.AUTH.SIGN_UP.HEADER');
      case AuthDialog.NON_EMAIL_SIGN_UP:
        return 'Zarejestruj się bez hasła';
      case AuthDialog.FORGOT_PASSWORD:
        return t('FEATURES.AUTH.FORGOT_PASSWORD.HEADER');
      case AuthDialog.VERIFY_EMAIL:
        return dialogConfig.title ?? 'Potwierdź adres email';
      default:
        return '';
    }
  };

  return (
    <Modal
      width={450}
      open={showDialog}
      onCancel={closeModal}
      title={getTitle()}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}>
      <Divider className="mt-0" />
      {dialogConfig?.content === AuthDialog.SIGN_IN && <SignIn onSuccess={dialogConfig?.onSuccess} />}
      {dialogConfig?.content === AuthDialog.SIGN_UP && <SignUp onSuccess={dialogConfig?.onSuccess} />}
      {dialogConfig?.content === AuthDialog.NON_EMAIL_SIGN_UP && <NonEmailSignUp onSuccess={dialogConfig?.onSuccess} />}
      {dialogConfig?.content === AuthDialog.FORGOT_PASSWORD && <ForgotPassword />}
      {dialogConfig?.content === AuthDialog.VERIFY_EMAIL && <Step3Form />}
    </Modal>
  );
};
