import { Alert, Button, Modal } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Anchor } from '../../../../../components/Buttons/Anchor';
import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { Typography } from '../../../../../components/Typography/Typography';
import { firebaseFunctions } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../../firebase/firebase.models';
import { useGetOffersByUid } from '../../../../../firebase/hooks/getOffersByUid.hook';
import { OfferStatusState } from '../../../../../models/offers.model';
import { useAuth } from '../../../../Auth/AuthContext';
import { AppRoutes } from '../../../../Routing/routing.model';

export const DeleteAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser, signOut } = useAuth();

  const [allOffersInactive, setAllOffersInactive] = useState(true);
  const [offers, loading] = useGetOffersByUid(currentUser.uid);
  const [latestDate, setLatestExpiration] = useState<Date>();

  const [countdown, setCountdown] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const isUserLoggedOut = urlParams.get('loggedOut') === 'true';

  useEffect(() => {
    if (isUserLoggedOut) {
      showModal();
      setIsLoading(true);
      const onDeleteAccountConfirm = firebaseFunctions.httpsCallable(
        FirebaseCallableFunctions.ON_DELETE_ACCOUNT_CONFIRM
      );
      onDeleteAccountConfirm({ userUid: currentUser.uid })
        .then(() => {
          toastService.show('Wysłano link na adres email do potwierdzenia usunięcia konta', 'Sukces');
          setIsModalOpen(false);
          setIsLoading(false);
        })
        .catch(() => {
          toastService.show('Cos poszło nie tak. Spróbuj później', 'Błąd', { variant: ToastVariant.ERROR });
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const activeOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.ACTIVE);
    const expiration = activeOffers?.map((o) => o.offerValidTo);
    if (expiration?.length == 1) {
      setLatestExpiration(expiration[0]);
      setAllOffersInactive(false);
    } else if (activeOffers?.length > 1) {
      setAllOffersInactive(false);
      const latestExpiration = expiration?.reduce((latestDate, currentDate) => {
        if (currentDate > latestDate) {
          return currentDate;
        }
        return latestDate;
      });
      setLatestExpiration(latestExpiration);
    } else if (expiration?.length == 0) {
      setAllOffersInactive(true);
    }
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Oblicz pozostały czas
      const now = new Date();
      const timeDifference = latestDate?.getTime() - now.getTime();

      // Jeśli czas wygasł, zatrzymaj odliczanie
      if (timeDifference <= 0) {
        clearInterval(interval);
      } else {
        // Obliczaj dni, godziny, minuty i sekundy
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        // Utwórz format daty
        const countdownString = `${days}d ${hours}h ${minutes}m ${seconds}s`;

        // Uaktualnij stan z odliczanym czasem
        setCountdown(countdownString);
      }
    }, 1000);

    // Wyczyszczenie interwału po zamontowaniu komponentu
    return () => clearInterval(interval);
  }, [latestDate]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    window.history.pushState({}, '', `${AppRoutes.MOJA_TABLICA_ACCOUNT_INFO}/?loggedOut=true`);
    signOut();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Alert
        style={{ backgroundColor: 'white' }}
        message="Usuwanie konta"
        type="error"
        action={
          <Button onClick={showModal} style={{ float: 'right' }}>
            Usuń konto
          </Button>
        }
      />

      <Modal
        title={'Usuń swoje konto'}
        open={isModalOpen}
        okButtonProps={{
          style: allOffersInactive ? null : { display: 'none' },
          loading: isLoading,
        }}
        cancelButtonProps={{ disabled: isLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Potwierdź"
        cancelText="Zamknij">
        {allOffersInactive ? (
          <>
            <p>
              Czy na pewno chcesz usunąć swoje konto na naszej platformie? Ta operacja jest nieodwracalna i spowoduje
              trwałe usunięcie wszystkich danych i informacji powiązanych z Twoim kontem.
            </p>
            <p>
              Jeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z{' '}
              <Anchor href="/moja-tablica/advanced/support">naszym zespołem obsługi klienta.</Anchor>
            </p>
            <p>
              Jeśli jesteś pewien/a swojej decyzji, prosimy kliknąć poniższy przycisk, aby wysłać wiadomość e-mail w
              celu potwierdzenia usunięcia konta.
            </p>
            <br></br>
            <Typography.H5>Aby usunąć konto należy zalogować się ponownie.</Typography.H5>
          </>
        ) : (
          <>
            <p>Przepraszamy za niedogodność!</p>
            <p>
              Nie możesz jeszcze usunąć swojego konta, ponieważ masz aktywną wystawioną ofertę na naszej platformie do
              najmu nieruchomości. W celu zapewnienia uczciwości wobec innych użytkowników, musisz poczekać, aż oferta
              stanie się nieaktywna, zanim będziesz mógł usunąć swoje konto.
            </p>
            <p>
              Dziękujemy za korzystanie z naszej platformy. Jeśli masz jakiekolwiek pytania lub potrzebujesz dodatkowej
              pomocy, skontaktuj się z
              <Anchor href="/moja-tablica/advanced/support">naszym zespołem obsługi klienta.</Anchor>
            </p>
            <br></br>
            <Typography.H5>Czas do momentu wygaśnięcia wszystkich aktywnych ofert: {countdown}</Typography.H5>
          </>
        )}
      </Modal>
    </>
  );
};
