// import dayjs from 'dayjs';
import * as React from 'react';
import { useMemo } from 'react';
import { Panel, PanelGroup, Table } from 'rsuite';
import styled from 'styled-components';

import { PanelContent } from '../../../components/Layout/styles';
import { Offer } from '../../../models/offers.model';

import { SignInToShow } from './SignInToShow';

const PanelWrapper = styled.div`
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
`;

interface Props {
  offer: Offer;
  defaultExpanded?: boolean;
  isUserLoggedIn: boolean;
}
export const DetailsAndFunctions: React.FC<Props> = ({ offer, defaultExpanded = true, isUserLoggedIn }) => {
  // formatowanie Daty
  const formattedAvailability = offer.availability.toLocaleDateString('pl-PL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const determineRemainingDetailsStructured = (legalMainUse, relatedDetails) => {
    if (legalMainUse === 'Biurowe') {
      return [
        { id: '1', labelName: 'Podłogi uniesione', value: relatedDetails.raisedFloors ? 'Tak' : 'Nie' },
        { id: '2', labelName: 'Sufity podwyższone', value: relatedDetails.raisedCeilings ? 'Tak' : 'Nie' },
        { id: '3', labelName: 'Alarm z czujnikami ruchu', value: relatedDetails.alarm ? 'Tak' : 'Nie' },
        { id: '4', labelName: 'Monitoring video', value: relatedDetails.monitoring ? 'Tak' : 'Nie' },
        { id: '5', labelName: 'Okna otwierane', value: relatedDetails.openingWindows ? 'Tak' : 'Nie' },
        { id: '6', labelName: 'Wyposażenie biura', value: (relatedDetails.officeEquipment || []).join(', ') },
      ];
    } else if (legalMainUse === 'Handlowo-Usługowe') {
      return [
        { id: '1', labelName: 'Dowolna aranżacja', value: relatedDetails.anyArrangement ? 'Tak' : 'Nie' },
        {
          id: '2',
          labelName: 'Niezależny kanał wentylacyjny',
          value: relatedDetails.independentVentilationChannel ? 'Tak' : 'Nie',
        },
        {
          id: '3',
          labelName: 'Słupy wewnątrz hali',
          value:
            relatedDetails.columnsGridStructure?.x && relatedDetails.columnsGridStructure?.y
              ? `${relatedDetails.columnsGridStructure.x} cm na ${relatedDetails.columnsGridStructure.y} cm`
              : 'Nie',
        },
        { id: '4', labelName: 'Ilość wejść', value: relatedDetails.numberOfEntries?.toString() || 'Brak wejść' },
      ];
    } else {
      return [
        { id: '1', labelName: 'Wyposażenie magazynu', value: (relatedDetails.warehouseEquipment || []).join(', ') },
        { id: '2', labelName: 'Wysokość hali do konstrukcji', value: `${relatedDetails.hallHeight} m` },
        { id: '3', labelName: 'Suwnica', value: relatedDetails.crane ? 'Tak' : 'Nie' },
        { id: '4', labelName: 'Rampa samochodowa', value: relatedDetails.carRamp ? 'Tak' : 'Nie' },
        { id: '5', labelName: 'Obsługa magazynowa', value: relatedDetails.warehouseHandling ? 'Tak' : 'Nie' },
        { id: '6', labelName: 'Regały wysokiego składowania', value: relatedDetails.highBayRacking ? 'Tak' : 'Nie' },
        { id: '7', labelName: 'Wózek widłowy', value: relatedDetails.forklift ? 'Tak' : 'Nie' },
        { id: '8', labelName: 'Wentylacja mechaniczna', value: relatedDetails.mechanicalVentilation ? 'Tak' : 'Nie' },
        {
          id: '9',
          labelName: 'Magazyn jest na poziomie 0',
          value: relatedDetails.groundLevelWarehouse ? 'Tak' : 'Nie',
        },
        {
          id: '10',
          labelName: 'Winda',
          value: relatedDetails.lift ? `Tak, Udźwig windy: ${relatedDetails.liftCapacityKg} kg` : 'Nie',
        },
        { id: '11', labelName: 'Otwory okienne', value: relatedDetails.windowOpenings ? 'Tak' : 'Nie' },
        { id: '12', labelName: 'Posadzka bezpyłowa', value: relatedDetails.dustFreeFloor ? 'Tak' : 'Nie' },
        { id: '13', labelName: 'Wjazd do hali TIR', value: relatedDetails.TIRHallEntrance ? 'Tak' : 'Nie' },
        { id: '14', labelName: 'Rampa kolejowa', value: relatedDetails.railRamp ? 'Tak' : 'Nie' },
        { id: '15', labelName: 'Wentylacja grawitacyjna', value: relatedDetails.naturalVentilation ? 'Tak' : 'Nie' },
        {
          id: '17',
          labelName: 'Słupy wewnątrz hali',
          value:
            relatedDetails.columnsGridStructure?.x && relatedDetails.columnsGridStructure?.y
              ? `${relatedDetails.columnsGridStructure.x} cm na ${relatedDetails.columnsGridStructure.y} cm`
              : 'Nie',
        },
      ];
    }
  };

  const dataOthers = useMemo(
    () => determineRemainingDetailsStructured(offer.legalMainUse, offer.relatedDetails),
    [offer]
  );

  const dataMedia = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Przydział mocy',
        value: `${offer.mediaDetails.powerAllocation} kW`,
      },
      {
        id: '2',
        labelName: 'Kat. energetyczna',
        value: offer.mediaDetails.energyCategory,
      },
      {
        id: '3',
        labelName: 'Klimatyzacja',
        value: offer.mediaDetails.airConditioning ? 'Tak' : 'Nie',
      },
      {
        id: '4',
        labelName: 'Wentylacja grawitacyjna',
        value: offer.mediaDetails.gravityVentilation ? 'Tak' : 'Nie',
      },
      {
        id: '5',
        labelName: 'Rekuperacja',
        value: offer.mediaDetails.heatRecovery ? 'Tak' : 'Nie',
      },
      {
        id: '6',
        labelName: 'Stacja trafo na działce',
        value: offer.mediaDetails.transformerIn ? 'Tak' : 'Nie',
      },
      {
        id: '7',
        labelName: 'Podwójne zasilanie',
        value: offer.mediaDetails.doublePowerLines ? 'Tak' : 'Nie',
      },
      {
        id: '8',
        labelName: 'Woda',
        value: offer.mediaDetails.water ? 'Tak' : 'Nie',
      },
      {
        id: '9',
        labelName: 'Gaz',
        value: offer.mediaDetails.gas ? 'Tak' : 'Nie',
      },
      {
        id: '10',
        labelName: 'Kanalizacja',
        value: offer.mediaDetails.sewerage ? 'Tak' : 'Nie',
      },
      {
        id: '11',
        labelName: 'Ogrzewanie',
        value: offer.mediaDetails.heating ? 'Tak' : 'Nie',
      },
      {
        id: '12',
        labelName: 'Światłowód',
        value: offer.mediaDetails.fiber ? 'Tak' : 'Nie',
      },
      {
        id: '13',
        labelName: 'Sieć komputerowa',
        value: offer.mediaDetails.LAN ? 'Tak' : 'Nie',
      },
      {
        id: '14',
        labelName: 'Sieć telefoniczna',
        value: offer.mediaDetails.phoneLine ? 'Tak' : 'Nie',
      },
    ],
    [offer]
  );
  const dataParking = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Powierzchnia całego parkingu',
        value: `${offer.parkingDetails.parkingSurface} m²`,
      },
      {
        id: '2',
        labelName: 'Całkowita liczba miejsc parkingowych',
        value: `${offer.parkingDetails.totalParkingSurface}`,
      },
      {
        id: '3',
        labelName: 'Parking strzeżony',
        value: offer.parkingDetails.guardedParking ? 'Tak' : 'Nie',
      },
      {
        id: '4',
        labelName: 'Liczba miejsc parkingowych Lokalu',
        value: `${offer.parkingDetails.parkingLotsAvailable}`,
      },
    ],
    [offer]
  );
  const dataFinancial = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Czynsz netto, bez VAT',
        value: `${offer.price} zł`,
      },
      {
        id: '2',
        labelName: 'Kaucja z VAT (brutto)',
        value: `${offer.leaseAgreement.deposit} zł`,
      },
      {
        id: '3',
        labelName: 'Termin zapłaty czynszu najmu z góry do dnia',
        value: `${offer.leaseAgreement.payDay}`,
      },
      {
        id: '4',
        labelName: 'Pierwszy czynsz płatny od',
        value: `${offer.leaseAgreement.firstPaymentDay}`,
      },
      {
        id: '5',
        labelName: 'Opłaty poza czynszem',
        value:
          `${offer.leaseAgreement.nonRentFees}` +
          `${
            offer.leaseAgreement.nonRentFees === 'Ryczałtowe' ? ` (Ryczałt: ${offer.leaseAgreement.lumpSum} zł)` : ''
          }` +
          `${
            offer.leaseAgreement.nonRentFees === 'Licznikowe'
              ? offer.leaseAgreement.averageMonthlyCostsAnnualizedKnown
                ? ` (średniomiesięczne koszty w ujęciu rocznym nieznane)`
                : ` (średniomiesięczne koszty w ujęciu rocznym: ${offer.leaseAgreement.averageMonthlyCostsAnnualized} ${offer.priceCurrency} [Brutto])`
              : ''
          }`,
      },
    ],
    [offer]
  );
  const dataLease = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Oferta ważna do',
        value: `${formatDate(offer.offerValidTo, 'date')}`,
      },
      {
        id: '2',
        labelName: 'Lokal dostępny',
        value: `Od ${formatDate(offer.availability, 'date')} do ${
          offer.availabilityTo ? formatDate(offer.availabilityTo, 'date') : 'nieokreślono'
        }`,
      },
      {
        id: '3',
        labelName: 'Nieprzekraczalny czas odbioru Lokalu',
        value: `${offer.unitReceiptDeadline} dni`,
      },
      {
        id: '4',
        labelName: 'Umowy najmu na okres',
        value:
          `${
            offer.leaseAgreement.infinite
              ? `Na czas nieokreślony z ${offer.leaseAgreement.leaseNoticePeriod} miesięcznym okresem wypowiedzenia`
              : ''
          }` +
          `${
            offer.leaseAgreement.fixedshort
              ? `${offer.leaseAgreement.infinite ? ', ' : ''}do ${
                  offer.leaseAgreement.fixedshortMonths || 'X'
                } miesięcy`
              : ''
          }` +
          `${
            offer.leaseAgreement.fixedlong
              ? `${offer.leaseAgreement.infinite || offer.leaseAgreement.fixedshort ? ', ' : ''}do ${
                  offer.leaseAgreement.fixedlongMonths || 'Y'
                } miesięcy`
              : ''
          }`,
      },
      {
        id: '5',
        labelName: 'Załączone własne warunki umowy najmu',
        value: `${offer.ownTerms.selected ? 'Tak' : 'Nie'}`,
      },
      // {
      //   id: '6',
      //   labelName: 'Data i godzina przekazania',
      //   value: `${dayjs(offer.receptionTime.date).format('DD.MM.YYYY')} ${dayjs(offer.receptionTime.hour).format(
      //     'HH:mm'
      //   )}`,
      // },
      // {
      //   id: '6',
      //   labelName: 'Włączona opcja kontroferty',
      //   value: `${offer.counteroffer.selected ? 'Tak' : 'Nie'}`,
      // },
    ],
    [offer]
  );
  const dataAddress = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Województwo',
        value: offer.address.officialAddress.province,
      },
      {
        id: '2',
        labelName: 'Powiat',
        value: offer.address.officialAddress.county,
      },
      {
        id: '3',
        labelName: 'Gmina',
        value: offer.address.officialAddress.district,
      },
      {
        id: '4',
        labelName: 'Dzielnica/Miejscowość',
        value: offer.address.officialAddress.estate,
      },
      {
        id: '5',
        labelName: 'Osiedle/Rejon',
        value: offer.address.officialAddress.street,
      },
      {
        id: '6',
        labelName: 'Ulica i numer porządkowy',
        value: offer.address.officialAddress.houseNumber,
      },
    ],
    [offer]
  );
  const dataSurfaces = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Pow. użytkowa do wynajęcia',
        value: `${offer.areaDetails.surface ?? '0'} m²`,
      },
      {
        id: '6',
        labelName: 'Powierzchnia ca budynku',
        value: `${offer.areaDetails.totalSurface ?? '0'} m²`,
      },
      {
        id: '8',
        labelName: 'Nr działki',
        value: offer.address.plotNumber,
      },
      {
        id: '9',
        labelName: 'Nr księgi wieczystej',
        value: offer.address.landAndMortgageRegisterNumber,
      },
      {
        id: '9.5',
        labelName: 'Rodzaj własności',
        value: offer.address.ownershipType,
      },
      {
        id: '10',
        labelName: 'Typ budynku',
        value: offer.areaDetails.buildingType,
      },
      {
        id: '11',
        labelName: 'Materiał konstrukcyjny',
        value: offer.areaDetails.constructionMaterial,
      },
      {
        id: '12',
        labelName: 'Typ budynku',
        value: offer.areaDetails.buildingType,
      },
      {
        id: '14',
        labelName: 'Izolacja cieplna',
        value: offer.areaDetails.thermalInsulation,
      },
      {
        id: '15',
        labelName: 'Rok zakończenia budowy',
        value: offer.areaDetails.constructionCompletionYear,
      },
      {
        id: '16',
        labelName: 'Powierzchnia gruntu działki',
        value: `${offer.areaDetails.landArea}  m²`,
      },
      {
        id: '17',
        labelName: 'Dojazd',
        value: offer.areaDetails.drive.map((e) => e).join(', '),
      },
      {
        id: '18',
        labelName: 'Otoczenie/Sąsiedztwo',
        value: offer.areaDetails.surroundings,
      },
      {
        id: '19',
        labelName: 'Odległość od środków komunikacji',
        value: offer.areaDetails.publicTransportDistance,
      },
    ],
    [offer, formattedAvailability]
  );

  const roomMapper = (rooms, label, parentId, idxStart) => {
    return {
      id: `${parentId}-${idxStart}`,
      labelName: label,
      value: '',
      children: rooms.map((room, idx) => {
        return {
          id: `${parentId}-${idxStart}-${idx + 1}`,
          labelName: room.type || '',
          value: `${room.surface || ''} m²  ${room.windows ? 'z oknami' : 'bez okien'}`,
        };
      }),
    };
  };

  const dataLevels = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Liczba kondygnacji',
        value: offer.areaDetails.levels.length,
        children: offer.areaDetails.levels
          .sort((a, b) => (a.levelNumber < b.levelNumber ? -1 : 1))
          .map((o, i) => {
            const children = [
              {
                id: `1-${i + 1}-1`,
                labelName: 'Powierzchnia ca kondygnacji',
                value: `${o.surface} m²`,
              },
              {
                id: `1-${i + 1}-2`,
                labelName: 'Wysokość',
                value: `${o.height} cm`,
              },
              {
                id: `1-${i + 1}-4`,
                labelName: 'Ilość klatek schodowych',
                value: `${o.numberOfStaircases} szt.`,
              },
              {
                id: `1-${i + 1}-5`,
                labelName: 'Dostępność dla niepełnosprawnych',
                value: o.accessibility ? 'Tak' : 'Nie',
              },
              {
                id: `1-${i + 1}-6`,
                labelName: 'Toalety wspólne poza Lokalem',
                value: o.toiletsLocation ? 'Tak' : 'Nie',
              },
              {
                id: `1-${i + 1}-7`,
                labelName: 'Inne pomieszczenia',
                value: o.otherRooms ? o.otherRoomsDetails.map((r) => r).join(', ') : 'Brak',
              },
            ];

            let idxStart = children.length + 1;

            if (o.commercialAndServiceRooms && o.commercialAndServiceRooms.length > 0) {
              children.push(
                roomMapper(o.commercialAndServiceRooms, 'Pomieszczenia handlowe i usługowe', `1-${i + 1}`, idxStart++)
              );
            }

            if (o.offices && o.offices.length > 0) {
              children.push(roomMapper(o.offices, 'Pomieszczenia biurowe', `1-${i + 1}`, idxStart++));
            }

            if (o.technicalRooms && o.technicalRooms.length > 0) {
              children.push(roomMapper(o.technicalRooms, 'Pomieszczenia techniczne', `1-${i + 1}`, idxStart++));
            }

            if (o.warehouses && o.warehouses.length > 0) {
              children.push(roomMapper(o.warehouses, 'Pomieszczenia magazynowe', `1-${i + 1}`, idxStart++));
            }

            return {
              id: `1-${i + 1}`,
              labelName: o.levelName,
              value: o.levelNumber,
              children: children,
            };
          }),
      },
    ],
    [offer]
  );

  const dataLegal = useMemo(
    () => [
      {
        id: '1',
        labelName: 'Główna funkcja Lokalu',
        value: offer.legalMainUse,
      },
      {
        id: '2',
        labelName: 'Przeznaczenie akceptowalne',
        value: offer.legalWarehouseUseAccepted.reduce((a, c) => a + ' ' + c + ',', ''),
      },
      {
        id: '3',
        labelName: 'Przeznaczenie zgodne',
        value: offer.legalWarehouseUseOfficial.reduce((a, c) => a + ' ' + c + ',', ''),
      },
    ],
    [offer]
  );

  return (
    <PanelWrapper>
      <PanelGroup accordion>
        <Panel header={'Adres'} collapsible bodyFill defaultExpanded>
          <PanelContent>
            <TableTree data={dataAddress} />
          </PanelContent>
        </Panel>
        <Panel header={'Przeznaczenie'} collapsible bodyFill defaultExpanded>
          <PanelContent>
            <TableTree data={dataLegal} />
          </PanelContent>
        </Panel>
        <Panel header={'Budynek'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataSurfaces} />
          </PanelContent>
        </Panel>
        <Panel header={'Kondygnacje'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataLevels} />
          </PanelContent>
        </Panel>
        <Panel header={'Parking'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataParking} />
          </PanelContent>
        </Panel>
        <Panel header={'Media'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataMedia} />
          </PanelContent>
        </Panel>
        <Panel header={'Pozostałe'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            <TableTree data={dataOthers} />
          </PanelContent>
        </Panel>
        <Panel header={'Kwestie Finansowe'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            {isUserLoggedIn ? (
              <TableTree data={dataFinancial} />
            ) : (
              <SignInToShow text="Zaloguj się, aby zobaczyć szczegóły oferty" rows={6} />
            )}
          </PanelContent>
        </Panel>
        <Panel header={'Kwestie Umowy'} collapsible bodyFill defaultExpanded={defaultExpanded}>
          <PanelContent>
            {isUserLoggedIn ? (
              <TableTree data={dataLease} />
            ) : (
              <SignInToShow text="Zaloguj się, aby zobaczyć szczegóły oferty" rows={6} />
            )}
          </PanelContent>
        </Panel>
      </PanelGroup>
    </PanelWrapper>
  );
};

const TableTree: React.FC<{ data: any[] }> = ({ data }) => {
  return (
    <Table isTree defaultExpandAllRows rowKey="id" autoHeight wordWrap width={800} data={data} headerHeight={0}>
      <Table.Column width={320}>
        <Table.HeaderCell />
        <Table.Cell dataKey="labelName" />
      </Table.Column>

      <Table.Column width={480}>
        <Table.HeaderCell />
        <Table.Cell dataKey="value" />
      </Table.Column>
    </Table>
  );
};

export function formatDate(dateString: Date, type = 'time'): string {
  let dateOptions: any;
  if (type === 'date') {
    dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
  } else {
    dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
  }
  const date = new Date(dateString);
  return date.toLocaleString('pl-PL', dateOptions);
}
