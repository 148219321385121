import { Button, Form, Input } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { gtm } from '../../../../reportGTM';
import { UserProfileFormValues } from '../../auth.model';
import { useValidation2 } from '../hooks/useValidation.hook';

export const Step2Form: React.FC<{ onNext: (values: UserProfileFormValues) => void }> = ({ onNext }) => {
  const validation2 = useValidation2();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // Initial values for Step 2
  const initialStep2Values: UserProfileFormValues = {
    firstName: '',
    lastName: '',
  };

  return (
    <Formik
      initialValues={initialStep2Values}
      validationSchema={validation2}
      onSubmit={(values: UserProfileFormValues, formikHelpers: FormikHelpers<UserProfileFormValues>) => {
        gtm('form_submit_reg_step_2', { state: 'FirstNameAndLastNameSubmitted' });
        onNext(values); // Proceed to step 3
        formikHelpers.setSubmitting(false); // Set isSubmitting to false after finishing
      }}>
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting, isValid }) => (
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')}
            validateStatus={touched.firstName && errors.firstName ? 'error' : ''}
            help={touched.firstName && errors.firstName ? errors.firstName : ''}>
            <Input
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item
            label={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')}
            validateStatus={touched.lastName && errors.lastName ? 'error' : ''}
            help={touched.lastName && errors.lastName ? errors.lastName : ''}>
            <Input
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')}
            />
          </Form.Item>

          <Form.Item>
            <Button
              id="form_reg_submit"
              loading={loading}
              type="primary"
              block
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setLoading(true);
              }}
              htmlType="submit">
              {t('FEATURES.AUTH.SIGN_UP.BUTTON_SUBMIT')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
