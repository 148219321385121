// FileUploadComponent.tsx
import { InboxOutlined } from '@ant-design/icons';
import { Spin, Upload } from 'antd';
import React from 'react';

import { Verification } from '../../../../../models/offers.model';

interface FileUploadComponentProps {
  bankAccount: string;
  uploaded: boolean;
  identity: Verification;
  isUploading: boolean;
  fileList: any[];
  customUpload: (options: any) => Promise<void>;
  handleDownloadRegulamin: () => Promise<void>;
}

export const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  bankAccount,
  uploaded,
  identity,
  isUploading,
  fileList,
  customUpload,
  handleDownloadRegulamin,
}) => {
  const { Dragger } = Upload;

  if (!uploaded && identity !== Verification.ACCEPTED && identity !== Verification.CHECKING) {
    return (
      <>
        <Dragger
          name="file"
          multiple={false}
          fileList={fileList}
          customRequest={customUpload}
          disabled={!bankAccount}
          onDrop={(e) => {
            console.log('Dropped files', e.dataTransfer.files);
          }}
          showUploadList={false}
          style={{ marginTop: '10px', marginBottom: '16px' }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined rev={undefined} />
          </p>
          <p className="ant-upload-text">Kliknij lub przeciągnij, żeby załączyć podpisany regulamin</p>
          <p className="ant-upload-hint">
            <a
              onClick={(e) => {
                handleDownloadRegulamin();
                e.stopPropagation();
              }}
              className="underline"
              rel="noreferrer">
              Pobierz regulamin,
            </a>{' '}
            który należy podpisać{' '}
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="ant-upload-hint underline"
              href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
              target="_blank"
              rel="noreferrer">
              Podpisem Zaufanym
            </a>
          </p>
        </Dragger>
        {isUploading ? <Spin style={{ marginTop: '20px' }} /> : null}
      </>
    );
  } else {
    return null;
  }
};
