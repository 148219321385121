import { HeartFilled } from '@ant-design/icons';
import { Button, Card, message, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useAuth } from '../../features/Auth/AuthContext';
import { AppRoutes } from '../../features/Routing/routing.model';
import { firebaseFunctions, firebaseStorage, firestore } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../firebase/firebase.models';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { ToastVariant } from '../Toast/toast.model';
import { toastService } from '../Toast/toast.service';

import { DeleteFavorite } from './components/DeleteFavorite';
import { DeleteOfferModal } from './components/DeleteOfferModal';
import { DocumentsOfferModal } from './components/DocumentsOfferModal';
import { DropdownButton } from './components/DropdownButton';
import { OfferBadges } from './components/OfferBadges';
import { OfferLocationButton } from './components/OfferLocationButton';
import { OfferPrice } from './components/OfferPrice';
import { RejectProtocolModal } from './components/RejectProtocolModal';

interface Props {
  offer: Offer;
  onLocationClick?: (id: string) => void;
  state?: OfferStatusState;
  addToFavoritesButton?: boolean;
  favorites?: Offer[];
}

export const OfferCardHorizontal: React.FC<Props> = ({
  offer,
  onLocationClick,
  state = OfferStatusState.ACTIVE,
  addToFavoritesButton = true,
}) => {
  const { isAuth, showSignInModal, currentUser } = useAuth();
  const { push } = useHistory();
  const { t } = useTranslation();
  const images = useGetOfferImages(offer.id, 0, true);
  const [heartColor, setheartColor] = useState('red');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [isRejectProtocolModalOpen, setIsRejectProtocolModalOpen] = useState(false);

  const redirectTo = useCallback(
    (path) => {
      push(path);
    },
    [push]
  );

  const handleFirestoreUpdate = useCallback(async (ref, newData) => {
    try {
      await ref.update(newData);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }, []);

  const onShowOffer = useCallback(() => {
    const destination = `${AppRoutes.OFFER_ROOT}/${offer.id}`;
    redirectTo(destination);
  }, [isAuth, offer.id]);

  const onEditOffer = useCallback(() => {
    redirectTo(AppRoutes.ADD_OFFER.replace(':id', offer.id));
  }, [offer.id]);

  const onDeleteOffer = useCallback(async () => {
    const offerRef = firestore.collection(FirestoreCollection.OFFERS).doc(offer.id);
    const userRef = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);

    await handleFirestoreUpdate(offerRef, { 'offerStatus.state': OfferStatusState.DELETED });

    const doc = await userRef.get();
    if (!doc.exists) {
      console.log('No such document!');
      return;
    }

    const userOffers = (doc.data().offers || []).filter((item) => item !== offer.id);
    await handleFirestoreUpdate(doc.ref, { offers: userOffers });
  }, [offer.id]);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const toggleDocumentsModal = () => setIsDocumentsModalOpen((prev) => !prev);
  const toggleRejectProtocolModal = () => setIsRejectProtocolModalOpen((prev) => !prev);

  const handleOk = () => {
    onDeleteOffer();
    toggleModal();
  };

  const onDuplicateOffer = useCallback(async () => {
    const hide = message.loading('Duplikowanie oferty...', 0);

    const originalDoc = await firestore.collection(FirestoreCollection.OFFERS).doc(offer.id).get();

    if (!originalDoc.exists) {
      console.error('Oferta nie istnieje!');
      return;
    }

    const originalOfferRef = firestore.collection(FirestoreCollection.OFFERS).doc(offer.id);
    const userRef = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);

    try {
      const doc = await originalOfferRef.get();
      if (!doc.exists) {
        console.log('No such document!');
        return;
      }

      const offerData = Object.fromEntries(
        Object.entries({
          ...doc.data(),
          offerStatus: {
            state: OfferStatusState.UNSIGNED,
          },
          counteroffer: {
            selected: doc.data().counteroffer.selected,
          },
        }).filter(
          ([key]) =>
            ![
              'receptionOfThePremise',
              'receptionTime',
              'transferOfThePremise',
              'bookedBy',
              'signLandlordDate',
              'signTenantDate',
              'protocolData',
            ].includes(key)
        )
      );

      // Pobierz dane oferty i zmień niektóre dane
      const docRef = await firestore.collection(FirestoreCollection.OFFERS).add(offerData);
      const newOfferId = docRef.id;
      console.log('newOfferId', newOfferId);

      docRef.update({ id: newOfferId });

      // kopiuj zdjęcia a dokłądnie folder /images
      const originalOfferId = doc.id;
      const storageRef = firebaseStorage.ref();

      // Funkcja do kopiowania zdjęć z jednego folderu do drugiego
      const copyImages = async (sourceFolderRef, destFolderRef) => {
        const imagesList = await sourceFolderRef.listAll();

        for (const image of imagesList.items) {
          const imageBlob = await image.getDownloadURL().then((url) => fetch(url).then((res) => res.blob()));
          const newImageRef = destFolderRef.child(image.name);
          await newImageRef.put(imageBlob);
        }
      };

      // Kopiowanie głównych zdjęć
      const originalImagesFolderRef = storageRef.child(`offers/${originalOfferId}/images`);
      const newImagesFolderRef = storageRef.child(`offers/${newOfferId}/images`);
      await copyImages(originalImagesFolderRef, newImagesFolderRef);

      // Kopiowanie mniejszych zdjęć
      const originalSmallImagesFolderRef = storageRef.child(`offers/${originalOfferId}/images/small`);
      const newSmallImagesFolderRef = storageRef.child(`offers/${newOfferId}/images/small`);
      await copyImages(originalSmallImagesFolderRef, newSmallImagesFolderRef);

      const userDoc = await userRef.get();
      if (!userDoc.exists) {
        console.log('No such user document!');
        return;
      }

      const userOffers = userDoc.data().offers || [];
      await handleFirestoreUpdate(userRef, { offers: [...userOffers, newOfferId] });
      hide(); // Zakończenie wiadomości ładowania
      message.success('Oferta została zduplikowana.');
    } catch (error) {
      hide(); // Zakończenie wiadomości ładowania
      message.error('Błąd podczas duplikowania oferty.');
      console.error('Error duplicating offer: ', error);
    }
  }, [offer.id]);

  const addToFavorites = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_TO_FAVORITES);
  const onAddToFavorites = useCallback(() => {
    if (!isAuth) {
      showSignInModal({
        title: 'Zaloguj się aby dodać Ofertę do ulubionych',
        onSuccess: () => {
          location.reload();
        },
      });
    } else {
      if (isLoading) return;
      setIsLoading(true);
      setheartColor('slate');

      addToFavorites({ offerId: offer.id, uid: currentUser.uid })
        .then(() => {
          toastService.show(t('COMMON.OFFER.ACTIONS.ADD_TO_FAVORITES'), 'Dodano do ulubionych');
          setIsLoading(false);
        })
        .catch(() => {
          toastService.show('Cos poszło nie tak. Spróbuj później', 'Błąd', { variant: ToastVariant.ERROR });
          setIsLoading(false);
        });
    }
  }, [isLoading, currentUser, offer.id]);

  return (
    <>
      <Card
        onClick={() => {
          window.location.pathname.includes(AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS) ? onEditOffer() : onShowOffer();
        }}
        className="m-2 max-w-sm hover:shadow-md"
        hoverable
        cover={
          images?.[0] ? (
            <img src={images?.[0]} style={{ height: 222 }} />
          ) : (
            <img src="/images/assets/no-image-svgrepo-com.svg" style={{ height: 222 }} />
          )
        }
        extra={
          <>
            {addToFavoritesButton && (
              <Button
                className="absolute right-2 top-2"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddToFavorites();
                }}
                icon={isLoading ? <Spin /> : <HeartFilled className={`text-${heartColor}-600`} />}
              />
            )}
            {window.location.pathname.includes(AppRoutes.MOJA_TABLICA) &&
              !window.location.pathname.includes(AppRoutes.MOJA_TABLICA_FAVORITES) && (
                <DropdownButton
                  showModal={toggleModal}
                  offer={offer}
                  state={state}
                  duplicate={onDuplicateOffer}
                  showDocuments={toggleDocumentsModal}
                  isLandlord={currentUser?.uid === offer.uid}
                  onRejectProtocol={toggleRejectProtocolModal}
                  onShowOffer={onShowOffer}
                />
              )}
            {window.location.pathname.includes(AppRoutes.MOJA_TABLICA_FAVORITES) && (
              <DeleteFavorite offerId={offer.id} uid={currentUser.uid} />
            )}
          </>
        }
        title={offer.name ? offer.name : 'Brak nazwy'}>
        <Meta
          description={
            <>
              <OfferPrice price={offer.price} />
              <OfferBadges offer={offer} />
              <OfferLocationButton offer={offer} onLocationClick={onLocationClick} />
            </>
          }
        />
        {/* <CardContent>
          <CardContentHeader>
            <CardContentHeaderDescription>
              <OfferCardOfferType>{t(`COMMON.OFFER.OFER_TYPE.${offer.offerType.toUpperCase()}`)}</OfferCardOfferType>
              <Name>{offer.name}</Name>
            </CardContentHeaderDescription>
          </CardContentHeader>
          <OfferBadges offer={offer} />
          <StyledDivider />
          <CardContentFooter>
            <OfferLocationButton offer={offer} onLocationClick={onLocationClick} />
            <div className="float-right mb-2">
              {state === OfferStatusState.ACTIVE && (
                <Button color="green" onClick={onShowOffer} loading={isAuthenticating}>
                  Zobacz
                </Button>
              )}
              {state === OfferStatusState.UNSIGNED && (
                <Button color="orange" onClick={onEditOffer} loading={isAuthenticating} style={{ margin: '6px' }}>
                  Edytuj
                </Button>
              )}
              {state !== OfferStatusState.ACTIVE && state !== OfferStatusState.UNSIGNED && (
                <Button color="blue" onClick={onShowOffer} loading={isAuthenticating} style={{ margin: '6px' }}>
                  Zobacz
                </Button>
              )}
            </div>
          </CardContentFooter>
        </CardContent> */}
      </Card>
      <DeleteOfferModal handleOk={handleOk} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <DocumentsOfferModal
        isModalOpen={isDocumentsModalOpen}
        toggleModal={toggleDocumentsModal}
        offer={offer}
        isLandlord={currentUser?.uid == offer.uid}
      />
      <RejectProtocolModal
        isModalOpen={isRejectProtocolModalOpen}
        toggleModal={toggleRejectProtocolModal}
        offerId={offer.id}
        uid={currentUser?.uid}
      />
    </>
  );
};
