import { InfoCircleOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, InputRef, Tooltip } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { gtm } from '../../../../reportGTM';
import { SignUpFormValues } from '../../auth.model';
import { useAuth } from '../../AuthContext';
import { GoogleSignInButton } from '../../SignIn/component/GoogleSignInButton';
import { useValidation1 } from '../hooks/useValidation.hook';

export const Step1Form: React.FC<{ onNext: (values: SignUpFormValues) => void }> = ({ onNext }) => {
  const validation1 = useValidation1();
  const { signInWithGoogle } = useAuth();
  const { t } = useTranslation();
  const inputRef = useRef<InputRef>(null);

  const [passwordScore, setPasswordScore] = useState<number>(0);

  // Initial values for Step 1
  const initialStep1Values: SignUpFormValues = {
    email: '',
    password: '',
    passwordConfirm: '',
    marketingConsent: false,
  };

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'start',
    });
  }, []);

  const checkPasswordStrength = (password: string) => {
    const strength = {
      length: password.length >= 8,
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      upperLowerCase: /[a-z]/.test(password) && /[A-Z]/.test(password),
    };

    // Calculate the score based on true conditions
    return Object.values(strength).filter(Boolean).length;
  };

  const renderStrengthBars = (score: number) => {
    const colors = ['#ff4d4f', '#faad14', '#fadb14', '#52c41a']; // Colors for weak to strong

    // Create an array of 4 bars, each colored based on the score
    const bars = Array(4)
      .fill(false)
      .map((_, index) => index < score);

    return (
      <div style={{ display: 'flex', gap: '4px', marginTop: '8px' }}>
        {bars.map((active, index) => (
          <div
            key={index}
            style={{
              width: '20px',
              height: '6px',
              backgroundColor: active ? colors[index] : '#e8e8e8',
              borderRadius: '2px',
              transition: 'background-color 0.3s ease',
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialStep1Values}
        validationSchema={validation1}
        onSubmit={(values: SignUpFormValues, formikHelpers: FormikHelpers<SignUpFormValues>) => {
          onNext(values); // Proceed to step 2
          formikHelpers.setSubmitting(false); // Set isSubmitting to false after finishing
          gtm('form_submit_reg_step_1', { state: 'emailAndPasswordSubmitted' });
        }}>
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting, isValid }) => (
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              validateStatus={touched.email && errors.email ? 'error' : ''}
              help={touched.email && errors.email ? errors.email : ''}
              label={t('FEATURES.AUTH.SIGN_UP.INPUT_EMAIL_PLACEHOLDER')}>
              <Input
                ref={inputRef}
                name="email"
                prefix={<MailOutlined />}
                placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_EMAIL_PLACEHOLDER')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.password && errors.password ? 'error' : ''}
              help={touched.password && errors.password ? errors.password : ''}
              label={
                <div>
                  {t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_PLACEHOLDER')}{' '}
                  <Tooltip
                    title={
                      <div>
                        <p>Wymagania dotyczące siły hasła:</p>
                        <ul style={{ paddingLeft: '20px', margin: 0 }}>
                          <li>Minimum 8 znaków</li>
                          <li>Co najmniej jedna wielka i jedna mała litera</li>
                        </ul>
                      </div>
                    }>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginLeft: '4px' }} />
                  </Tooltip>
                </div>
              }>
              <Input.Password
                name="password"
                prefix={<LockOutlined />}
                placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_PLACEHOLDER')}
                value={values.password}
                onChange={(e) => {
                  handleChange(e);
                  const score = checkPasswordStrength(e.target.value);
                  setPasswordScore(score);
                }}
                onBlur={handleBlur}
              />
              {renderStrengthBars(passwordScore)}
            </Form.Item>

            <Form.Item
              validateStatus={touched.passwordConfirm && errors.passwordConfirm ? 'error' : ''}
              help={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''}
              label={t('FEATURES.AUTH.SIGN_UP.INPUT_CONFIRM_PASSWORD_PLACEHOLDER')}>
              <Input.Password
                name="passwordConfirm"
                prefix={<LockOutlined />}
                placeholder={t('FEATURES.AUTH.SIGN_UP.INPUT_CONFIRM_PASSWORD_PLACEHOLDER')}
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item>
              <Checkbox name="marketingConsent" checked={values.marketingConsent} onChange={handleChange}>
                {t('FEATURES.AUTH.SIGN_UP.INPUT_MARKETING_CONSENT_PLACEHOLDER')}
              </Checkbox>
            </Form.Item>

            <Form.Item className="flex justify-center items-center">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={isSubmitting || !isValid}
                className="mt-4 px-12">
                {t('FEATURES.AUTH.SIGN_UP.BUTTON_SUBMIT')}
              </Button>
            </Form.Item>

            <Divider plain>lub</Divider>
          </Form>
        )}
      </Formik>
      <div className="w-full flex justify-center items-center h-full">
        <GoogleSignInButton onClick={signInWithGoogle} signUp={true} />
      </div>
    </>
  );
};
