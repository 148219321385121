import { Alert, Button, Space } from 'antd';
import React from 'react';
import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

import { Article, WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { Offer, OfferStatusState } from '../../../models/offers.model';
import { useOnAddOfferPush } from '../../App/hooks/onAddOfferPush.hook';

export const StyledArticle = styled(Article)`
  margin-top: -50px;
  margin-bottom: -80px;
`;

interface Pops {
  offers: Offer[];
}

export const MojaTablicaOffers: React.FC<Pops> = ({ offers }) => {
  const onAddOfferPush = useOnAddOfferPush();

  const activeOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.ACTIVE);
  const bookedOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.BOOKED);
  const unsignedOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.UNSIGNED);
  const signedOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.SIGNED);

  // const newOffers = offers?.filter((o) => o.offerStatus.state === OfferStatusState.NEW);
  // console.log('Liczba pustych projektów:', newOffers?.length);

  console.log(offers);

  if (activeOffers?.length > 0 || unsignedOffers?.length > 0 || signedOffers?.length > 0) {
    return (
      <>
        {activeOffers && (
          <StyledArticle>
            <WidthWrapper>
              <Space direction="vertical" style={{ marginLeft: 20 }}>
                <Typography.H6>Oferty</Typography.H6>
              </Space>
              <FlexboxGrid>
                {activeOffers.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal
                      offer={o}
                      state={o.offerStatus.state as OfferStatusState}
                      addToFavoritesButton={false}
                    />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        )}
        {unsignedOffers && (
          <StyledArticle>
            <WidthWrapper>
              <Space direction="vertical" style={{ marginLeft: 20 }}>
                <Typography.H6>Projekty</Typography.H6>
              </Space>
              <FlexboxGrid>
                {unsignedOffers.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal
                      offer={o}
                      state={o.offerStatus.state as OfferStatusState}
                      addToFavoritesButton={false}
                    />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        )}
        {signedOffers && (
          <StyledArticle>
            <WidthWrapper>
              <Space direction="vertical" style={{ marginLeft: 20 }}>
                <Typography.H6>Umowy najmu</Typography.H6>
              </Space>
              <FlexboxGrid>
                {signedOffers.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal
                      offer={o}
                      state={o.offerStatus.state as OfferStatusState}
                      addToFavoritesButton={false}
                    />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        )}
        {bookedOffers.length > 0 && (
          <StyledArticle>
            <WidthWrapper>
              <Space direction="vertical" style={{ marginLeft: 20 }}>
                <Typography.H6>Zarezerwowane Oferty</Typography.H6>
              </Space>
              <FlexboxGrid>
                {bookedOffers.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal
                      offer={o}
                      state={o.offerStatus.state as OfferStatusState}
                      addToFavoritesButton={false}
                    />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        )}
        <div className="mb-48"></div>
      </>
    );
  } else if (activeOffers?.length === 0 && unsignedOffers?.length === 0 && signedOffers?.length === 0) {
    return (
      <Space direction="vertical" style={{ margin: 50 }}>
        <Alert
          message="Brak ofert"
          showIcon
          description="Nie masz jeszcze żadnych stworzonych przez ciebie ofert. Kliknij w przycisk, żeby utworzyć nową ofertę"
          type="info"
          action={
            <Button type="primary" onClick={onAddOfferPush}>
              Dodaj Lokal
            </Button>
          }
        />
      </Space>
    );
  } else {
    return (
      <Space direction="vertical" style={{ margin: 50 }}>
        <Alert message="Błąd" description="Nie udało się załadować ofert" type="error" showIcon />
      </Space>
    );
  }
};
